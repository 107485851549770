import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router';

import { useAuth } from '../../context/AuthContext';
import { useApi } from '../../context/ApiContext'
import { validatePhoneNumber } from '../../common/stringHelper'
import { handleSelect } from '../../common/javascriptHelper';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useLoadingIndicator } from '../../context/LoadingIndicatorContext';
import { getFileData } from '../../common/imageHelper';

function SignupInfo() {

  let navigate = useNavigate()
  const location = useLocation()
  const api = useApi()
  const {alert} = useCommonPopup()

  const [inputs, setInputs] = useState({
    name: "",
    gender: "m",
    phoneno: "",
    affiliation: "",
    occupation: "",
  })

  const [errors, setErrors] = useState({
    nameError: "",
    phonenoError: "",
    affiliationError: "",
    occupationError: "",
  })

  const {name, gender, phoneno, affiliation, occupation} = inputs
  const {nameError, phonenoError, affiliationError, occupationError} = errors
  const [file, setFile] = useState()
  const [imageData, setImageData] = useState()
  const [selectedAffiliation, setSelectedAffiliation] = useState()

  const inputName = useRef()
  const inputPhoneno = useRef()
  const inputAffiliation = useRef()

  const {showLoading, stopLoading} = useLoadingIndicator()

  const [isLoading, setIsLoading] = useState(false)

  const [affiliationList, setAffiliationList] = useState()

  useEffect(_ => {

    if (!location.pathname.includes("markup") && (location.state?.email == null || location.state?.password == null)) {
      alert('잘못된 접근입니다.')
      navigate(-1)
    }

    getAffiliationList()

  }, []);

  const getAffiliationList = async() => {

    const result = await api.post("getAffiliationList", {
    })

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setAffiliationList(result.data.data.affiliations)
    }
  }

  const setError = (name, error) => {
    setErrors({
      ...errors,
      [`${name}Error`]: error
    })
  }

  const onChangeInput = (e) => {

    var { value, name } = e.target
    value = value.replace(/ /g, "")
    setInputs({
      ...inputs,
      [name]: value
    })

    if (name == "name") {
      if (value.length > 0) {
        setError("name", "")
      }
    } else if (name == "phoneno") {
      if (value.length > 0 && !validatePhoneNumber(value)) {
        setError("phoneno", "휴대폰 번호는 숫자만 입력해주세요.")
      } else {
        setError("phoneno", "")
      }
    } else if (name == "affiliation") {

    }
  }

  const onKeyEvent = (e) => {

    var { name } = e.target
    if (e.key === 'Enter') {
      if (name == "name") {
        inputPhoneno.current.focus()
      } else if (name == "phoneno") {
        inputAffiliation.current.focus()
      } else if (name == "affiliation") {
      }
    }
  }

  const onSelectFile = async (e) => {

    const file = e.target.files[0]
    if (!file) return

    let data = await getFileData(file)

    setFile(file)
    setImageData(data)
  }

  const onDeleteFile = async (e) => {

    setFile()
    setImageData()
  }

  const onSelectGender = (value) => {

    setInputs({
      ...inputs,
      ["gender"]: value
    })
  }

  const onClickSelect = (e) => {
    handleSelect(e)
  }

  const onSelectAffiliation = (value) => {

    setSelectedAffiliation(value)
  }

  const onSelectOccupation = (e) => {

    setInputs({
      ...inputs,
      ["occupation"]: e.target.innerText
    })
    setError("occupation", "")
  }

  const onClickCancel = async _ => {
    navigate("/signin", {replace: true})
  }

  const checkValidation = _ => {

    if (name.length == 0) {
      inputName.current.focus()
      setError("name", "이름을 입력해 주세요.")
      return false
    }
    if (phoneno.length == 0) {
      inputPhoneno.current.focus()
      setError("phoneno", "휴대폰번호를 입력해 주세요.")
      return false
    }
    if (!validatePhoneNumber(phoneno)) {
      setError("phoneno", "휴대폰 번호는 숫자만 입력해주세요.")
      return false
    }
    if (occupation.length == 0) {
      setError("occupation", "직업을 선택해 주세요.")
      return false
    }
    return true
  }

  const onClickNext = async _ => {

    if (isLoading) return
    if (!checkValidation()) return

    setIsLoading(true)
    showLoading()

    var filename = null;
    if (file != null) {
      filename = await uploadFile()
      if (!filename) {
        return
      }
    }

    const result = await api.request("/admins/signup/", {
      method: "POST",
      params: {
        email : location.state.email,
        appauthkey : location.state.appauthkey,
        password : location.state.password,
        name,
        gender,
        phoneno,
        affiliation : selectedAffiliation === "직접 입력" ? affiliation : selectedAffiliation,
        occupation,
        profileimage : filename,
        agreements: location.state.agreements
      }
    })

    stopLoading()
    if (result?.status !== 200) {
      if (result.data?.detail === "이미 사용중인 전화번호입니다.") {
        setError("phoneno", result.data?.detail)
      } else if (result.data?.detail && result.data?.detail.length > 0) {
        alert(`회원가입 실패.\n(${result.data.detail})`)
      } else {
        alert(`회원가입 실패`)
      }
      setIsLoading(false)
    } else {

      setIsLoading(false)
      api.setAccessToken(result.data.accesstoken)
      navigate("/signup/3", { state: {
        email: location.state.email,
        accesstoken: result.data.accesstoken
      }
    })
    }
  }

  const uploadFile = async _ => {

    if (file == null) return null

    let data = await getFileData(file)
    if (data == null) return null

    const result = await api.post("uploadProfileImage", {
      file: data,
    })

    if (result?.data?.result != "ok") {
      stopLoading()
      setIsLoading(false)
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
      return null
    } else {
      return result.data.data.filename
    }
  }

  return (
    <div id="join_step2" className="body">
      {/* <div class="loading-container">
        <div>
          <div class="loading"></div>
          <div id="loading-text">uploading</div>
          </div>
      </div> */}
      <div  className="login join join_info">
        <div className="inwrap">
          <div>
            <ul>
              <li>
                <i className="icon-logo"></i>
                <h4>연구를 쉽고 빠르게!</h4>
                <p>
                  손쉽게 설문 또는 문진을 진행할 수 있습니다.
                </p>
                <ul>
                  <li>40여개의 표준화된 척도를 출처와 함께 제공</li>
                  <li>한눈에 볼 수 있는 요약통계와 통계 프로그램에 적합한 data set 제공</li>
                  <li>조건 지정 후 맞춤 패널 제공</li>
                </ul>
                <img src="../images/signin_1.png" alt=""/>
              </li>
              <li>
                <form action="" onSubmit={(e) => {e.preventDefault()}}>
                  <h4>가입 정보 입력</h4>
                  <dl>
                    <dt>이름 *</dt>
                    <dd>
                      <input
                        name="name"
                        type="text"
                        placeholder="이름"
                        ref={inputName}
                        className={nameError.length > 0 ? "error" : ""}
                        value={name}
                        onChange={onChangeInput}
                        onKeyUp={onKeyEvent}
                      />
                      <p className="tip">{nameError}</p>
                    </dd>
                    <dt>성별 *</dt>
                    <dd>
                      <ul className="tab">
                        <li className={gender != "f" ? "active" : ""} onClick={(e) => {onSelectGender("m")}}>남자</li>
                        <li className={gender == "f" ? "active" : ""} onClick={(e) => {onSelectGender("f")}}>여자</li>
                      </ul>
                      <p className="tip"></p>
                    </dd>
                    <dt>휴대폰 번호 *</dt>
                    <dd>
                      <input
                        name="phoneno"
                        type="text"
                        placeholder="휴대폰 번호"
                        ref={inputPhoneno}
                        className={phonenoError.length > 0 ? "error" : ""}
                        value={phoneno}
                        onChange={onChangeInput}
                        onKeyUp={onKeyEvent}
                      />
                      <p className="tip">{phonenoError}</p>
                    </dd>
                    <dt>소속기관</dt>
                    <dd>
                      <ul>
                        { selectedAffiliation === "직접 입력" &&
                          <li className="first_child">
                            <input
                              name="affiliation"
                              type="text"
                              placeholder="소속기관"
                              ref={inputAffiliation}
                              className={`full ${affiliationError.length > 0 ? "error" : ""}`}
                              value={affiliation}
                              onChange={onChangeInput}
                            />
                          </li>
                        }
                        <li>
                          <div className="selectBox full">
                            <span className="label" onClick={onClickSelect}>{selectedAffiliation?? "기관을 선택해 주세요."}</span>
                            <ul className="optionList">
                              { affiliationList?.map((affiliation, index) =>
                                  <li className="optionItem" key={`affiliation-${index}`} onClick={() => onSelectAffiliation(affiliation.name)}>{affiliation.name}</li>
                                )
                              }
                              <li className="optionItem" onClick={() => onSelectAffiliation("직접 입력")}>직접 입력</li>
                            </ul>
                          </div>
                        </li>

                      </ul>
                      <p className="tip">{affiliationError}</p>
                    </dd>
                    <dt>직업 *</dt>
                    <dd>
                      <div className="selectBox full">
                      <span className="label" onClick={onClickSelect}>{occupation.length > 0 ? occupation : "직업을 선택하세요."}</span>
                      <ul className="optionList active">
                          <li className="optionItem" onClick={onSelectOccupation}>대학생</li>
                          <li className="optionItem" onClick={onSelectOccupation}>대학원생(석사과정)</li>
                          <li className="optionItem" onClick={onSelectOccupation}>대학원생(박사과정)</li>
                          <li className="optionItem" onClick={onSelectOccupation}>보건</li>
                          <li className="optionItem" onClick={onSelectOccupation}>의료진</li>
                          <li className="optionItem" onClick={onSelectOccupation}>기타</li>
                      </ul>
                      </div>
                      <p className="tip">{occupationError}</p>
                    </dd>
                    <dt>프로필 이미지</dt>
                    <dd>
                      <div className="filebox">
                       <div>
                        {!!imageData && <img src={imageData} style={{width:"32px", height:"32px"}}/>}
                        <label htmlFor="input_file"><i className="icon-upload"></i>사진 첨부</label>
                        <input type="file" id="input_file" className="upload-hidden" onChange={onSelectFile}/>
                        <input className="upload-name" value="" disabled="disabled"/>
                        </div>
                        {!!imageData && <i className="icon-delete " onClick={onDeleteFile}></i>}
                      </div>

                    </dd>
                  </dl>
                  <div className="but_wrap">
                    <div>
                      <button type="button" className="secondary large" onClick={onClickCancel}>취소</button>
                    </div>
                    <div>
                      <button type="button"  className={`primary large ${isLoading && "disabled"}`} onClick={onClickNext}>저장</button>
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    )
  }

  export default SignupInfo