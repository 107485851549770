import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import AdminHeader from './AdminHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import PaginationLegacy from '../../component/PaginationLegacy';
import { decimalFormat, markKeyword } from '../../common/stringHelper';
import ChangeGradePopup from './popup/ChangeGradePopup';
import AdminMenu from './AdminMenu';
import { handleSelect, initPopupMenu } from '../../common/javascriptHelper';

const AdminCustomer = props => {
  
  const navigate = useNavigate()  
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()

  const [customerList, setCustomerList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const searchPage = useRef()
  const searchKeyword = useRef("")

  const [customerCount, setCustomerCount] = useState()

  const [keyword, setKeyword] = useState("")
  const [sortData, setSortData] = useState({
    sort: 'registerdate',
    order: 'desc'
  })

  const [selectAll, setSelectAll] = useState(false)
  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {
  
    getTotalCustomerCount()

  }, [])

  useEffect(_ => {
        
    getCustomerList(keyword, currentPage, pageLimit)

  }, [pageLimit, sortData])

  useEffect(_ => {
    
    if (searchPage.current == currentPage) return
    getCustomerList(keyword, currentPage, pageLimit)

  }, [currentPage])

  useEffect(_ => {
    
    if (searchKeyword.current !== keyword) {
      getCustomerList(keyword, currentPage, pageLimit)
    }
  }, [keyword])

  useEffect(_ => {
    
    setSelectAll(customerList?.filter(element => element.selected === false)?.length === 0)     

  }, [customerList])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getTotalCustomerCount = async () => {

    const result = await api.post("getTotalCustomerCount", {})    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      result.data.data.count['rate'] = result.data.data.count.currentMonth * 100.0/result.data.data.count.lastMonth 
      setCustomerCount(result.data.data.count)
    }
  }

  const getCustomerList = async (search, page, limit) => {

    searchKeyword.current = search

    const result = await api.post("getCustomerList", {
      page: page,
      limit: limit,
      search : search,
      orderby : sortData.sort,
      order : sortData.order
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
            
      setTotalCount(result.data.data.totalCount)
      const list = result.data.data.customers.map(element => 
        ({
          ...element,
          ["selected"] : false,
          ["display_name"] : markKeyword(element.name, search),
          ["display_email"] : markKeyword(element.email, search)
        })
      )
      setCustomerList(list)
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/
  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {
    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onChangeKeyword = (e) => {

    var value = e.target.value   
    if (keyword === value) return

    setKeyword(value)
  }
  
  const onKeyEvent = (e) => {

    if (e.key === 'Enter') {
      onClickSearch()
    }
  }

  const onClickSearch = async () => {

    await getCustomerList(keyword, currentPage, pageLimit)
  }

  const onClickSort = async (sort) => {

    if (sortData.sort == sort) {
      setSortData({
        sort: sort,
        order: sortData.order == "asc" ? "desc" : "asc"
      })
    } else {
      setSortData({
        sort: sort,
        order: "asc"
      })
    }    
  }

  const onSelectAllCustomer = (value) => {

    setSelectAll(value)
    setCustomerList(customerList.map((element) => 
      ({
        ...element,
        ["selected"] : value,
      })
    ))
  }

  /* 참여자 리스트 선택 */
  const onSelectCustomer = (item, value) => {

    setCustomerList(customerList.map((element) => 
      item === element ? {
        ...element,
        ["selected"] : value,
      } : element)
    )
  }
  
  const onChangeStatus = async (customer) => {

    if (customer.idadmin === 0 || customer.status === '탈퇴') return

    const result = await popup(
      <ChangeGradePopup 
        customerList={[customer]}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getCustomerList(keyword, currentPage, pageLimit)
    }
  }

  const checkGrade = (list) => {

    if (list.length === 0) return true

    var premium = list[0].premium
    for (let i = 1; i < list.length; i++) {
      if (premium !== list[i].premium) {
        return false; 
      }
    }
    return true
  }

  const onClickCustomer = async (customer) => {
    if (customer.idadmin === 0 || customer.status === '탈퇴') return
    navigate(`/admin/customer/${customer.idadmin}`)
  }

  const onClickChangeGrade = async () => {
    
    const list = customerList.filter((customer) => customer.selected === true)
    if (list?.length == 0) {
      alert("등급을 변경할 고객을 선택해 주세요.")
      return
    }

    if (!checkGrade(list)) {
      alert("고객 등급 정보가 동일해야 일괄 변경이 가능합니다. 다시 확인해 주세요.", {
        title: "고객 등급 변경 불가"
      })
      return
    }

    const result = await popup(
      <ChangeGradePopup 
      customerList={list}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getCustomerList(keyword, currentPage, pageLimit)
    }
  }

  const onClickGrade = async (customer) => {

    if (customer.status === '대기') return 

    const result = await popup(
      <ChangeGradePopup 
        customerList={[customer]}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getCustomerList(keyword, currentPage, pageLimit)
    }
  }
  
  /*=========================================================
   *  Render 
   =========================================================*/

  return (   
    <div className="body project admin">
      <AdminHeader/>
      <div className="container">
        <div className="inwrap">
          <div className={`full gap`}>
            <div className="csboard_wrap adminboard_wrap">
              <AdminMenu tab='customer'/>
              <div className='board_con'>
                <div className='header_wrap'>
                  <p>리서치플로우 총 가입자 수</p>
                    <h3>{!!customerCount?.totalCount ? decimalFormat(customerCount?.totalCount, 0) : '0'} 
                      <span>
                        <i className={`icon-caretdown ${customerCount?.rate > 0 ? 'up' : customerCount?.rate < 0 ? 'down' : ''}`}/> 
                        &nbsp;{!!customerCount?.rate ? decimalFormat(customerCount?.rate, 2) : '0'}% (전월 대비)
                      </span>
                    </h3>
                  <section className="search_box">
                    <input type="text" placeholder="이름, 아이디, 휴대푠 번호로 검색" value={keyword} onChange={onChangeKeyword} onKeyUp={onKeyEvent}/>
                    <button onClick={onClickSearch}><i className="icon-search"></i></button>
                  </section>
                </div>
                <div>
                  <table className='line'>
                    <colgroup>
                      <col className='no' />
                      <col className='col200'/>
                      <col/>
                      <col className='date'/>
                      <col className='date'/>
                      <col  className='date'/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="checkbox">
                          
                          <label>
                            <input type="checkbox" name="" checked={selectAll} onChange={(e) => onSelectAllCustomer(e.target.checked)}  /><span></span>
                            {/* <input type="checkbox" name=""/> */}
                            <span></span>
                          </label>
                        </th>
                        <th onClick={() => {onClickSort("name")}}>이름<i className={`icon-caretdown ${sortData.sort == "name" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                        <th onClick={() => {onClickSort("email")}}>ID<i className={`icon-caretdown ${sortData.sort == "email" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                        <th onClick={() => {onClickSort("registerdate")}}>가입일<i className={`icon-caretdown ${sortData.sort == "registerdate" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                        <th onClick={() => {onClickSort("status")}}>상태<i className={`icon-caretdown ${sortData.sort == "status" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                        <th onClick={() => {onClickSort("premium")}}>등급<i className={`icon-caretdown ${sortData.sort == "premium" && sortData.order == "desc" ? "" : "up"}`}></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      { customerList?.length > 0 ? (
                          customerList?.map((item, itemIndex) => 
                              <tr className={item.idadmin === 0 || item.status === '탈퇴' ? 'hover' : 'cursor hover'} key={`customer-${itemIndex}`}>
                                <td className="checkbox">
                                  <label>
                                    <input type="checkbox" name="" 
                                      checked={item.selected} 
                                      disabled={item.idadmin === 0 || item.status === '탈퇴'}
                                      onChange={(e)=>onSelectCustomer(item, e.target.checked)}/><span></span>
                                  </label>
                                </td>
                                <td onClick={() => onClickCustomer(item)}><div dangerouslySetInnerHTML={ {__html: item.display_name.length > 0 ? item.display_name : '-'} }/></td>
                                <td onClick={() => onClickCustomer(item)}><div dangerouslySetInnerHTML={ {__html: item.display_email.length > 0 ? item.display_email : '-'} }/></td>
                                <td onClick={() => onClickCustomer(item)}>{item.status === '대기' ? '미가입' : item.registerdate}</td>
                                <td onClick={() => onClickCustomer(item)}>{item.status}</td>
                                <td>
                                  { item.status === '대기' ? ('-') : (
                                      <div className="selectBox select_inline">
                                      <span className="label" onClick={() => onClickGrade(item)}>{item.status === '대기' ? '-' : item.premium === 1 ? '프리미엄' : '일반'}</span>
                                      {/* <ul className="optionList">
                                        <li className="optionItem" onClick={() => onClickGrade(item, 0)}>일반</li>
                                        <li className="optionItem" onClick={() => onClickGrade(item, 1)}>프리미엄</li>
                                      </ul> */}
                                      </div>
                                    )
                                  }
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr><td colSpan={7} className='center'>고객이 없습니다.</td></tr>
                          )
                        }
                    </tbody>
                  </table>
                  <div className='flex_between'>
                    <button className="secondary gray" onClick={onClickChangeGrade}>등급 변경</button>
                    <PaginationLegacy
                      totalCount={totalCount}
                      currentPage={currentPage}
                      pageSize={pageLimit}
                      changePageLimit={onChangePageLimit}
                      changeCurrentPage={onChangePage}
                    />
                  </div>
                </div>
              </div>        
            </div>        
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default AdminCustomer;