import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import AdminHeader from './AdminHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import PaginationLegacy from '../../component/PaginationLegacy';
import { decimalFormat, markKeyword } from '../../common/stringHelper';
import ChangeGradePopup from './popup/ChangeGradePopup';
import AdminCustomerDetailHeader from './AdminCustomerDetailHeader';
import { initPopupMenu } from '../../common/javascriptHelper';
import MemoPopup from '../project/popup/MemoPopup';
import InquiryPopup from './popup/InquiryPopup';

const AdminCustomerDetail = props => {
  
  const navigate = useNavigate()  
  const api = useApi()  
  const {popup, confirm, alert} = useCommonPopup()

  const { adminId } = useParams()
  const [customerInfo, setCustomerInfo] = useState()
  const [projectList, setProjectList] = useState()
  const [history, setHistory] = useState()

  const [inquiryList, setInquiryList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {
  
    getCustomerInfo()

  }, [])

  useEffect(_ => {
    
    initPopupMenu()

  }, [customerInfo])

  useEffect(_ => {
    
    getCustomerInquiryInfo(currentPage, pageLimit)

  }, [currentPage, pageLimit])

  /*=========================================================
   *  Data 
   =========================================================*/
  const getCustomerInfo = async () => {

    const result = await api.post("getCustomerInfo", {
      admin : adminId,
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setCustomerInfo(result.data.data.basic)   
      setProjectList(result.data.data.project)   
    }
  }

  const getCustomerInquiryInfo = async (page, limit) => {

    const result = await api.post("getCustomerInquiryInfo", {
      admin : adminId,
      page: page,
      limit: limit
    })
    
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setInquiryList(result.data.data.inquiry)   
      setTotalCount(result.data.data.totalCount)
    }
  }
  
  /*=========================================================
   *  Handler 
   =========================================================*/
   const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {

    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onClickInquiry = async (inquiry) => {

    const result = await popup(
      <InquiryPopup inquiry={inquiry}
      />, {
        container: "cs",
        name: "cs_inquiry_popup",
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getCustomerInquiryInfo(currentPage, pageLimit)
    }
  }
  /*=========================================================
   *  Render 
   =========================================================*/
  const ProjectItem = (props) => {

    const {item} = props

    const [showMemo, setShowMemo] = useState(false)

    const onClickMemo = () => {
      if (item?.myproject !== 1) {
        alert('권한이 없습니다.')
        return
      }
      setShowMemo(!showMemo)
    }

    const onClosedMemo = () => {
      setShowMemo(false)
    }

    return (
      <tr className="">
          <td>{item?.name}</td>
          <td className="oneLine">{item?.joindate}</td>
          <td>{item?.isowner === 1 ? '소유자' : '참여자'}</td>
          { item?.memothread?.length === 0 ? (
              <td className=''>-</td>
            ) : item?.myproject === 1 ? (
              <td className=''>
                <button className="text_only memo-btn" onClick={() => onClickMemo()}>{item?.memothread?.length}개</button>
                { showMemo && 
                  <MemoPopup 
                    projectId={item.project}
                    memothreads={item?.memothread} 
                    title={item?.name} 
                    showAnswer={true}
                    onClosed={onClosedMemo}
                  /> 
                }
              </td> 
            ) : (
              <td className='cursor' onClick={() => onClickMemo()}>
                {item?.memothread?.length}개
              </td>
            )
          }
      </tr>
    )
  }

  return (   
    <div className="body project admin">
      <AdminCustomerDetailHeader customer={customerInfo}/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
            <div className='adminCustomer_detail'>
              <table className="line">
                <colgroup>
                  <col className='col130' />
                  <col className='col130' />
                  <col className='col130' />
                  <col className='col130' />
                  <col className='col130' />
                  <col className='col130' />
                </colgroup>
                <thead>
                  <tr>
                    <th>소속 기관</th>
                    <th>이름</th>
                    <th>성별</th>
                    <th>휴대폰 번호</th>
                    <th>가입일</th>
                    <th>등급</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cursor hover">
                    <td>{customerInfo?.affiliation}</td>
                    <td>{customerInfo?.name}</td>
                    <td className="oneLine">{customerInfo?.gender === 'f' ? '여자' : customerInfo?.gender === 'm' ? '남자' : ''}</td>
                    <td>{customerInfo?.phoneno}</td>
                    <td className="oneLine">{customerInfo?.registerdate}</td>
                    <td>
                      <div className="selectBox select_inline">
                        <span className="label" >{customerInfo?.premium === 1 ? '프리미엄' : '일반'}</span>
                        <ul className="optionList">
                          <li className="optionItem">일반</li>
                          <li className="optionItem">프리미엄</li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='board_con'>
                <div>
                  <h5>프로젝트 참여 이력</h5>
                  <table className="line">
                    <colgroup>
                      <col width={'*'} />
                      <col className='col180' />
                      <col className='col180' />
                      <col className='col180' />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>프로젝트명</th>
                        <th>참여일</th>
                        <th>참여 자격</th>
                        <th>메모</th>
                      </tr>
                    </thead>
                    <tbody>
                      { projectList?.length > 0 ? (
                          projectList?.map((item, itemIndex) => 
                            <ProjectItem key={`project-${itemIndex}`} item={item}/>
                          )
                        ) : (
                          <tr><td colSpan={4} className='center'>프로젝트 참여 이력이 없습니다.</td></tr>
                        )
                      }                      
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='board_con'>
                <div>
                  <h5>고객 문의 히스토리</h5>
                  <table className="line">
                    <colgroup>
                      <col className='col280' />
                      <col className='' />
                      
                      <col className='col180' />
                      <col className='col180' />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제목</th>
                        <th>내용</th>
                        <th>문의 등록일</th>
                        <th>답변 발송일</th>
                      </tr>
                    </thead>
                    <tbody>
                      { inquiryList?.length > 0 ? (
                          inquiryList?.map((item, itemIndex) => 
                            <tr className="cursor hover" key={`inquiry-${itemIndex}`} onClick={() => onClickInquiry(item)}>
                              <td>{item.title}</td>
                              <td>{item.content}</td>                              
                              <td>{item.registerdate}</td>
                              {item.answered === 1 ? <td>{item.answerdate}</td> : <td className="c_primary_6" >미답변</td>}
                            </tr>
                          )
                        ) : (
                          <tr><td colSpan={4} className='center'>고객 문의 히스토리가 없습니다.</td></tr>
                        )
                      }   
                    </tbody>
                  </table>
                </div>
                <PaginationLegacy
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageSize={pageLimit}
                    changePageLimit={onChangePageLimit}
                    changeCurrentPage={onChangePage}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default AdminCustomerDetail;