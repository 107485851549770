import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CSHeader from './CSHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import { useAuth } from '../../context/AuthContext';
import PaginationLegacy from '../../component/PaginationLegacy';
import queryString from 'query-string';

const CSNotice = props => {
  
  const navigate = useNavigate()  
  const location = useLocation()
  const api = useApi()
  const auth = useAuth()
  const {popup, confirm, alert} = useCommonPopup()

  const [noticeList, setNoticeList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedNotice, setSelectedNotice] = useState()

  /*=========================================================
   *  useEffect 
   =========================================================*/
  
  useEffect(_ => {
  
    const query = queryString.parse(location.search);
    if (!!query?.notice) {
      getNoticeInfo(query.notice)
    }    

  }, [])

  useEffect(_ => {
    
    getNoticeList(currentPage, pageLimit)

  }, [currentPage, pageLimit])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getNoticeList = async (page, limit) => {

    const result = await api.post("getNoticeList", {
      page: page,
      limit: limit
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setTotalCount(result.data.data.totalCount)
      setNoticeList(result.data.data.notices)
    }
  }

  const getNoticeInfo = async (idnotice) => {

    const result = await api.post("getNoticeInfo", {
      notice: idnotice,
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      setSelectedNotice(result.data.data.notice)
    }
  }
  /*=========================================================
   *  Handler 
   =========================================================*/
  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {

    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onClickNotice= async () => {
    navigate("/cs/notice")
  }

  const onClickInquiry= async () => {
    navigate("/cs/inquiry")
  }

  const onClickFAQ= async () => {
    navigate("/cs/faq")
  }

  const onClickNoticeItem = async (notice) => {
    getNoticeInfo(notice.idnotice)
  }
  /*=========================================================
   *  Render 
   =========================================================*/

  return (   
    <div className="body project cs">
      <CSHeader/>
      <div className="container">
        <div className="inwrap">
        <div className={`full gap`}>
            <div className="csboard_wrap">
              <div className='menu_list'>              
                <button onClick={onClickNotice} className="active">공지사항</button>            
                <button onClick={onClickFAQ}>자주 묻는 질문</button>
                <button onClick={onClickInquiry}>1:1문의</button>
              </div>

              <div className='board_con'>
                <div>
                  <h5>공지사항</h5>
                  <table className='line'>
                    <colgroup>
                      <col className='date'/>
                      <col />
                      <col className='col120'/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제목</th>
                        <th>내용</th>
                        <th>날짜</th>
                      </tr>
                    </thead>
                    <tbody>
                      { noticeList?.length > 0 ? (
                          noticeList?.map((item, itemIndex) => 
                            <tr className={`cursor hover ${selectedNotice?.idnotice === item.idnotice ? 'active' : ''}`}  
                              key={`notice-${itemIndex}`} 
                              onClick={() => { onClickNoticeItem(item)}}>
                              <td className='oneLine'>{item.title}</td>
                              <td className='oneLine'>{item.content}</td>
                              <td>{item.startdate}</td>
                            </tr>
                          )                          
                        ) : (
                          <tr><td colSpan='3' className='center'>공지사항이 없습니다.</td></tr>
                        )
                      }
                    </tbody>
                  </table>
                  <PaginationLegacy
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageSize={pageLimit}
                    changePageLimit={onChangePageLimit}
                    changeCurrentPage={onChangePage}
                  />
                </div>  
                { !!selectedNotice && (
                    <div className='view'>
                      <h5 className='fontw_600'>{selectedNotice.title}</h5>
                      <div>
                        {selectedNotice.content}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default CSNotice;