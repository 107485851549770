import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CSHeader from './CSHeader';
import { useCommonPopup } from '../../context/CommonPopupContext';
import { useApi } from '../../context/ApiContext';
import CSInquiryPopup from './popup/CSInquiryPopup';
import PaginationLegacy from '../../component/PaginationLegacy';

const CSInquiry = props => {
  
  const navigate = useNavigate()  
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()

  const [inquiryList, setInquiryList] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [inquiryInfo, setInquiryInfo] = useState()

  /*=========================================================
   *  useEffect 
   =========================================================*/
  
   useEffect(_ => {
  
   }, [])
 
   useEffect(_ => {
     
    getMyInquiryList(currentPage, pageLimit)
 
   }, [currentPage, pageLimit])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getMyInquiryList = async (page, pageLimit) => {

    const result = await api.post("getMyInquiryList", {
      page: Number(page),
      limit: Number(pageLimit)
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      setInquiryList([])
    } else {
      setTotalCount(result.data.data.totalCount)
      setInquiryList(result.data.data.inquiry)
    }
  }

  const getMyInquiryInfo = async (inquirycontent) => {

    const result = await api.post("getMyInquiryInfo", {
      inquirycontent: inquirycontent
    })    

    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      result.data.data.inquiry['idinquirycontent'] = inquirycontent
      setInquiryInfo(result.data.data.inquiry)
    }
  }
  /*=========================================================
   *  Handler 
   =========================================================*/
  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {

    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onClickNotice= async () => {
    navigate("/cs/notice")
  }

  const onClickInquiry= async () => {
    navigate("/cs/inquiry")
  }

  const onClickFAQ= async () => {
    navigate("/cs/faq")
  }

  const onClickMyInquiry = (inquirycontent) => {

    getMyInquiryInfo(inquirycontent)
  }

  const onClickNewInquiry = async () => {

    const result = await popup(
      <CSInquiryPopup 
      />, {
        container: "cs",
        name: "cs_inquiry_popup",
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getMyInquiryList(currentPage, pageLimit)
    }
  }

  const onClickReInquiry = async (inquiry) => {

    const result = await popup(
      <CSInquiryPopup inquiry={inquiry}
      />, {
        container: "cs",
        name: "cs_inquiry_popup",
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getMyInquiryList(currentPage, pageLimit)
    }
  }
  /*=========================================================
   *  Render 
   =========================================================*/

  return (   
    <div className="body project cs">
      <CSHeader/>
      <div className="container">
        <div className="inwrap">
          <div className={`full gap`}>
            <div className="csboard_wrap">
              <div className='menu_list'>              
                <button onClick={onClickNotice}>공지사항</button>            
                <button onClick={onClickFAQ}>자주 묻는 질문</button>
                <button onClick={onClickInquiry} className="active">1:1문의</button>
              </div>
              <div className='board_con'>
                { inquiryList?.length > 0 ? (
                  <>
                  <div>
                  <h5>
                    <span>나의 1:1 문의</span>
                    <button className="primary new_project_but" onClick={onClickNewInquiry}>문의하기</button>

                  </h5>
                  <table className='line'>
                    <colgroup>
                      <col className='date'/>
                      <col />
                      <col className='col120'/>
                      <col className='col100'/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>제목</th>
                        <th>내용</th>
                        <th>등록일</th>
                        <th>상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      { inquiryList?.map((item, itemIndex) => 
                           <tr className={`cursor hover ${inquiryInfo?.idinquirycontent === item.idinquirycontent ? 'active' : ''}`}  
                            key={`inquiry-${itemIndex}`}
                            onClick={() => {onClickMyInquiry(item.idinquirycontent)}}>
                            <td className='oneLine'>{item.title}</td>
                            <td >{item.content}</td>
                            <td>{item.registerdate}</td>
                            <td className={`${item.answered === 1 ? 'c_primary_6' : ''}`}>{item.answered === 1 ? '답변 완료' : '답변 대기'}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                  <PaginationLegacy
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageSize={pageLimit}
                    changePageLimit={onChangePageLimit}
                    changeCurrentPage={onChangePage}
                  />
                </div>
                { !!inquiryInfo && <div className='view'>
                    <h5 className='fontw_600'>상세 내용</h5>
                      <div className='view_con'>
                      <h6>질문</h6>
                      <h5>
                      [{inquiryInfo.title}] {inquiryInfo.content}
                      </h5>
                      </div>
                      <div className='view_con'>
                        <h6>답변</h6>
                        <h5>{inquiryInfo.answered === 1 ? inquiryInfo.answercontent : '답변 대기'}</h5>
                      </div>
                      {inquiryInfo.answered === 1 && <div className='view_foot'>
                          <span>답변이 만족스럽지 않으셨나요?</span> 
                          <button className="secondary gray" onClick={() => onClickReInquiry(inquiryInfo)}>재문의하기</button>
                        </div>
                      }
                    </div>
                  }
                  </>
                  ) : (
                    !!inquiryList &&  <div className='noQuestion'>
                        <div>
                        <img src="../../../assets/images/no_items.png" alt=""/>
                        <p>접수하신 문의가 없습니다.</p>
                        <button className="primary new_project_but"  onClick={onClickNewInquiry}>문의하기</button>
                        </div>
                      </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default CSInquiry;