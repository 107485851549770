import React, { useState, useEffect } from 'react';
import { handleSelect } from '../common/javascriptHelper';

// 공통 페이지네이션 component
const PaginationLegacy = props => {

    const {totalCount, currentPage, pageSize, changeCurrentPage, changePageLimit} = props;
    const pageGroupSize = 5
    const [pagingData, setPagingData] = useState(null);


    useEffect(_ => {
        if (totalCount && currentPage != null) {
            const totalPage = Math.ceil(totalCount / pageSize);
            const halfSize = Math.floor(pageGroupSize / 2)
            var startPage = 0, endPage = 0

            if (currentPage < halfSize ) {
              startPage = 0;
              endPage =  Math.min(currentPage + (pageGroupSize - (currentPage - startPage) - 1), totalPage - 1)
            } else if (currentPage >= totalPage - halfSize) {
              endPage =  totalPage - 1
              startPage = Math.max(0, currentPage - (pageGroupSize - (totalPage - currentPage)))
            } else {
              startPage = Math.max(0, currentPage - halfSize);
              endPage =  Math.min(totalPage - 1, currentPage + halfSize)
            }
            const startIndex = currentPage * pageSize + 1
            const endIndex = Math.min(currentPage * pageSize + pageSize, totalCount)

            setPagingData({ totalPage, startPage, endPage, startIndex, endIndex })
        }
    }, [totalCount, currentPage, pageSize])

    const onClickPrev = (e) => {

      if (currentPage > 0) {
        changeCurrentPage(currentPage - 1)
      }
    }

    const onClickNext = (e) => {

      if (currentPage < pagingData.totalPage - 1) {
        changeCurrentPage(currentPage + 1)
      }
    }

    const onClickPage = (page) => {

      changeCurrentPage(page);
    }

    const onClickSelect = (e) => {
      handleSelect(e)
    }

    const onSelectCount = (limit) => {

      changePageLimit(limit)
    }

    return (
      pagingData && currentPage !== null && pagingData.totalPage > 1 ? (
        <div className="pagination">
          {pagingData.startIndex}-{pagingData.endIndex} of {totalCount} items
          <ul>
            <li><a onClick={onClickPrev}><i className="icon-left"></i></a></li>
            { pagingData.startPage > 0 && <li><a
              className={currentPage == 0 ? "active" : ""} onClick={()=>onClickPage(0)}>1</a></li>
            }
            { pagingData.startPage > 1 && <li><i className="icon-dot-menu"></i></li>}
            {
              [...Array(pagingData.endPage - pagingData.startPage + 1)].map((x, i) =>
                <li key={`pagination-${i}`}><a className={currentPage == pagingData.startPage + i ? "active" : ""}
                onClick={()=>onClickPage(pagingData.startPage + i)}>{pagingData.startPage + i + 1}</a></li>
              )
            }
            { pagingData.endPage < pagingData.totalPage - 2 && <li><i className="icon-dot-menu"></i></li>}
            { pagingData.endPage < pagingData.totalPage - 1 &&
              <li><a className={currentPage == pagingData.totalPage - 1 ? "active" : ""}
              onClick={()=>onClickPage(pagingData.totalPage - 1)}>{pagingData.totalPage}</a></li>
            }
            <li><a onClick={onClickNext}><i className="icon-right"></i></a></li>
            { !!changePageLimit &&
              <li>
                <div className="selectBox">
                  <span className="label" onClick={onClickSelect}>{pageSize}/page</span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={()=>onSelectCount(10)}>10/page</li>
                    <li className="optionItem" onClick={()=>onSelectCount(20)}>20/page</li>
                    <li className="optionItem" onClick={()=>onSelectCount(50)}>50/page</li>
                    <li className="optionItem" onClick={()=>onSelectCount(100)}>100/page</li>
                  </ul>
                </div>
              </li>
            }
          </ul>
        </div>
    ) : (
      <div className="pagination"></div>
    ))
};

export default PaginationLegacy;
